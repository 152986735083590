import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import YouTube from "react-youtube"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/games.scss"
import "../../styles/game-page.scss"

// Images
import bg from "../../images/data/deadlink/meta/bg.jpg"

import keyart from "../../images/data/deadlink/hero/keyart.jpg"
import keyart_thumbnail from "../../images/data/deadlink/hero/keyart-thumbnail.jpg"
import gameplay_thumbnail from "../../images/data/deadlink/hero/gameplay-thumbnail.jpg"

import game_about from "../../images/data/deadlink/meta/icon.png"
import game_logo from "../../images/data/deadlink/meta/logo.png"
import game_developer from "../../images/data/deadlink/meta/developer.png"

import slide_1 from "../../images/data/deadlink/slider/a.jpg"
import slide_2 from "../../images/data/deadlink/slider/b.jpg"
import slide_3 from "../../images/data/deadlink/slider/c.jpg"
import slide_4 from "../../images/data/deadlink/slider/d.jpg"
import slide_5 from "../../images/data/deadlink/slider/e.jpg"
import slide_6 from "../../images/data/deadlink/slider/f.jpg"
import slide_7 from "../../images/data/deadlink/slider/g.jpg"
import slide_8 from "../../images/data/deadlink/slider/h.jpg"

import thumbnail_1 from "../../images/data/wardens/thumbnail.jpg"
import thumbnail_2 from "../../images/data/metaphora/thumbnail.jpg"
import thumbnail_3 from "../../images/data/broken-lines/thumbnail.jpg"

import game_logo_1 from "../../images/data/wardens/meta/logo.png"
import game_logo_2 from "../../images/data/metaphora/meta/logo.png"
import game_logo_3 from "../../images/data/broken-lines/meta/logo.png"

// Videos
import video from "../../videos/deadlink.mp4"

const GamePage = () => {

  return (
    <Layout>

      <Helmet>
        <body className="game-page"/>
      </Helmet>

      <SEO title="Deadlink"/>
      <Link to="/" className="logo" activeClassName="-active">Super!com</Link>
      <Link to="/games/" className="title">Games</Link>

      <div className="games">

        <div className="center">

          <section className="games__card -alone">

            <div className="games__card__bg -grayscale" style={{backgroundImage: 'url(' + bg + ')'}}></div>
            
            <h3 className="games__card__title">
              <span className="games__card__title__link">
                Deadlink
              </span>
            </h3>

            <div className="games__card__content">
              <div className="games__card__video">
                <div className="games__card__video__visible">
                  <img src={keyart} className="games__card__video__visible__size-setter"  alt=""/>
                  <div className="games__card__video__visible__item" data-id="keyart">
                    <video muted="muted" loop="loop" playsInline="playsinline" autoPlay="autoplay">
                      <source src={video} type="video/mp4"/>
                    </video>
                    <img src={keyart} className="games__card__video__visible__item__media"  alt=""/>
                  </div>
                  <div className="games__card__video__visible__item" data-id="gameplay">
                    <YouTube
                      videoId="glok5ozuIIE"
                      opts={{
                        playerVars: {
                          controls: 0,
                          playlist: 'glok5ozuIIE',
                          loop: 1,
                          showinfo: 0,
                          autoplay: 1,
                          start: 0,
                          mute: 1,
                          enablejsapi: 1
                        }
                      }}
                      // onReady={_onReady}
                    ></YouTube>
                  </div>
                </div>
                <button className="games__card__video__button -key-art -active" data-id="keyart" style={{backgroundImage: 'url(' + keyart_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Key Art</span>
                </button>
                <button className="games__card__video__button -gameplay" data-id="gameplay" style={{backgroundImage: 'url(' + gameplay_thumbnail + ')'}}>
                  <span className="games__card__video__button__name">Gameplay</span>
                </button>
              </div>
            </div>

            <footer className="games__card__footer">
              <div className="games__card__footer__about">
                <div className="games__card__footer__about__image">
                  <img src={game_about} alt="Raji"/>
                </div>
                <div className="games__card__footer__about__text">
                  Deadlink is a cyberpunk FPS with roguelite elements. You're the first operative to be recruited to the experimental Deadlink project. Pilot an autonomous combat shell, fighting your way through cramped slums, twisted labs, grimy warehouses, and sleek office buildings on a mission to thwart the schemes of the most powerful corporations in the world. Wield a deadly arsenal, upgrade your skills and tech, devastate destructible environments, and slash corporate profit margins — all at the same time.
                  <div className="games__card__footer__about__text__social">
                    <a href="https://youtube.com/playlist?list=PLEmXDZT81b-iGzQ2IiCkLMi_RPiPuKTH2" className="games__card__footer__about__text__social__item -youtube" target="_blank" rel="noopener noreferrer">youtube</a>
                    <a href="https://www.facebook.com/deadlinkgame" className="games__card__footer__about__text__social__item -facebook" target="_blank" rel="noopener noreferrer">facebook</a>
                    <a href="https://twitter.com/deadlinkgame" className="games__card__footer__about__text__social__item -twitter" target="_blank" rel="noopener noreferrer">twitter</a>
                    <a href="https://www.instagram.com/deadlinkgame/" className="games__card__footer__about__text__social__item -instagram" target="_blank" rel="noopener noreferrer">instagram</a>
                    <a href="https://discord.gg/8WzBWFJQA5" className="games__card__footer__about__text__social__item -discord" target="_blank" rel="noopener noreferrer">discord</a>
                  </div>
                  <div className="games__card__footer__about__text__developer">
                    <div className="games__card__footer__about__text__developer__label">Game developer</div>
                    <a href="https://grubyentertainment.com/" className="games__card__footer__about__text__developer__logo" target="_blank" rel="noopener noreferrer">
                      <img src={game_developer} alt=""/>
                    </a>
                  </div>
                </div>
              </div>
              <div className="games__card__footer__stores">
                <div className="games__card__footer__stores__line">
                  <a href="https://store.steampowered.com/app/1676130/Deadlink/" className="games__card__footer__stores__line__item -steam" target="_blank" rel="noopener noreferrer">steam</a>
                </div>
              </div>
              <a href="https://playdeflector.com" className="games__card__footer__logo" target="_blank" rel="noopener noreferrer">
                <img src={game_logo} className="games__card__footer__logo" alt="Deadlink"/>
              </a>
            </footer>
            
          </section>

        </div>

      </div>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Key <span className="game-section__title__text__accent">Features</span>
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__features">
              <div className="game-section__features__item">
                Be part of an <b>elite organization</b>
              </div>
              <div className="game-section__features__item">
                Think fast, <b>shoot faster</b>
              </div>
              <div className="game-section__features__item">
                Kill in style
              </div>
              <div className="game-section__features__item">
                Train your brain, <b>augment your brawn</b>
              </div>
            </div>

            <div className="game-section__slider -collapsed">
              <div className="glide">
                <div className="glide__track" data-glide-el="track">
                  <ul className="glide__slides">
                    <li className="glide__slide">
                      <img src={slide_1} alt="" data-index="0"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_2} alt="" data-index="1"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_3} alt="" data-index="2"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_4} alt="" data-index="3"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_5} alt="" data-index="4"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_6} alt="" data-index="5"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_7} alt="" data-index="6"/>
                    </li>
                    <li className="glide__slide">
                      <img src={slide_8} alt="" data-index="7"/>
                    </li>
                  </ul>
                </div>
                <div className="glide__arrows" data-glide-el="controls">
                  <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
                  <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>

      <section className="game-section -to-forward">
        <div className="center">

          <h4 className="game-section__title">
            <i></i>
            <span className="game-section__title__text">
              Game <span className="game-section__title__text__accent">Trailer</span>
            </span>
          </h4>

          <div className="game-section__content -trailer">
            <YouTube
              videoId="glok5ozuIIE"
              opts={{
                playerVars: {
                  controls: 1,
                  playlist: 'glok5ozuIIE',
                  loop: 1,
                  showinfo: 0,
                  autoplay: 0,
                  start: 0,
                  mute: 1,
                  enablejsapi: 1
                }
              }}
              // onReady={_onReady}
            ></YouTube>
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <div className="game-section__content">

            <div className="game-section__press-kit">
              <div className="game-section__press-kit__bg -grayscale" style={{backgroundImage: 'url(' + bg + ')'}}></div>
              <a href="https://deadlinkgame.com/presskit/presskit.zip" className="game-section__press-kit__link" target="_blank" rel="noopener noreferrer">
                <span className="game-section__press-kit__link__accent-text">Press <br/>Kit</span>
                <span className="game-section__press-kit__link__text">Let's see</span>
              </a>
            </div>
            
          </div>

        </div>
      </section>

      <section className="game-section">
        <div className="center">

          <h4 className="game-section__title -small">
            <i></i>
            <span className="game-section__title__text">
              You may like our other games
            </span>
          </h4>

          <div className="game-section__content">

            <div className="game-section__recents">
              <Link to="/games/alchemist-adventure/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_1 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>WARDENS THE LOST LEGACY</span>
                </span>
                <span className="game-section__recents__item__text">
                  Wardens: The Lost Legacy is a third-person action RPG where a mundane investigation brings detective Daniel Blake into a fantastical and magical world
                </span>
                <img src={game_logo_1} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/wardens/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_2 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>METAPHORA THE MOONY-CAT QUEST</span>
                </span>
                <span className="game-section__recents__item__text">
                  An animated action-adventure with catching
                  story about regenerating the world
                </span>
                <img src={game_logo_2} className="game-section__recents__item__image" alt=""/>
              </Link>
              <Link to="/games/broken-lines/" className="game-section__recents__item" style={{backgroundImage: 'url(' + thumbnail_3 + ')'}}>
                <span className="game-section__recents__item__title">
                  <span>Broken Lines</span>
                </span>
                <span className="game-section__recents__item__text">
                  Broken Lines is a story-driven tactical turnbased
                  RPG set in an alternative WW2
                </span>
                <img src={game_logo_3} className="game-section__recents__item__image" alt=""/>
              </Link>
            </div>

            <div className="games__promo__button">
              <button className="games__promo__button__link" data-role="call-to-action">
                <span className="games__promo__button__link__text">Give a boost to your game</span>
              </button>
              <div className="games__promo__button__shadow"></div>
            </div>

          </div>

        </div>
      </section>

      <div className="game-section__slider -expanded">
        <div className="glide">
          <div className="glide__track" data-glide-el="track">
            <ul className="glide__slides">
              <li className="glide__slide">
                <img src={slide_1} alt="" data-index="0"/>
              </li>
              <li className="glide__slide">
                <img src={slide_2} alt="" data-index="1"/>
              </li>
              <li className="glide__slide">
                <img src={slide_3} alt="" data-index="2"/>
              </li>
              <li className="glide__slide">
                <img src={slide_4} alt="" data-index="3"/>
              </li>
              <li className="glide__slide">
                <img src={slide_5} alt="" data-index="4"/>
              </li>
              <li className="glide__slide">
                <img src={slide_6} alt="" data-index="5"/>
              </li>
              <li className="glide__slide">
                <img src={slide_7} alt="" data-index="6"/>
              </li>
              <li className="glide__slide">
                <img src={slide_8} alt="" data-index="7"/>
              </li>
            </ul>
          </div>
          <div className="glide__arrows" data-glide-el="controls">
            <button className="glide__arrow glide__arrow--left" data-glide-dir="<">prev</button>
            <button className="glide__arrow glide__arrow--right" data-glide-dir=">">next</button>
          </div>
        </div>
      </div>
      
    </Layout>
  );

}

export default GamePage
